import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import ReCAPTCHA from "react-google-recaptcha";
import RichText from "./rich-text";

const ContactText = () => {
  const { locale } = useIntl();

  if (locale === "en") {
    return (
      <p>
        For any information request, we invite you to fill out the form below.
        You can also give us a call at{" "}
        <a href="tel:+33652395879">+33 6 52 39 58 79</a>.
      </p>
    );
  }

  return (
    <p>
      Pour toute demande d'information, nous vous invitons à remplir le
      formulaire ci-dessous ou à nous appeler au{" "}
      <a href="tel:+33652395879">+33 6 52 39 58 79</a>.
    </p>
  );
};

const ContactForm = () => {
  const { formatMessage, locale } = useIntl();
  const [success, setSuccess] = React.useState(null);
  const [phoneValue, setPhoneValue] = React.useState("");
  const formEl = React.useRef(null);
  const recaptchaEl = React.useRef(null);
  const newsletter = formatMessage({ id: "contactNewsletter" });
  const confirmation = formatMessage({ id: "contactConfirmation" });

  const handleSubmit = (event) => {
    event.preventDefault();
    recaptchaEl.current.execute();
  };

  const handleChange = async () => {
    const formData = new FormData(formEl.current);
    formData.append("phone", phoneValue);

    const email = formData.get("email");
    const firstname = formData.get("firstName");
    const lastname = formData.get("lastName");
    const phone = formData.get("phone");
    const newsletter = formData.get("_optin");

    const response = await fetch("https://formspree.io/f/xayavypj", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      await fetch("/api/newsletter-subscription", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          firstname,
          lastname,
          phone,
          locale,
          newsletter,
        }),
      });

      setSuccess(true);
      window.scrollTo(0, 0);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "FormSubmitOK",
        userEmail: email,
        userPhone: phone,
      });
    } else {
      setSuccess(false);
      formEl.current.reset();
      recaptchaEl.current.reset();
    }
  };

  return (
    <>
      <RichText>
        {!success && (
          <>
            <ContactText />
            <form ref={formEl} onSubmit={handleSubmit}>
              <input type="hidden" name="locale" value={locale} />
              <label>
                <input
                  type="text"
                  name="lastName"
                  autoComplete="family-name"
                  placeholder={formatMessage({ id: "contactLastName" })}
                  required
                />
                <span>
                  <FormattedMessage id="contactLastName" /> *
                </span>
              </label>
              <label>
                <input
                  type="text"
                  name="firstName"
                  autoComplete="given-name"
                  placeholder={formatMessage({ id: "contactFirstName" })}
                  required
                />
                <span>
                  <FormattedMessage id="contactFirstName" /> *
                </span>
              </label>
              <label>
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder={formatMessage({ id: "contactEmail" })}
                  required
                />
                <span>
                  <FormattedMessage id="contactEmail" /> *
                </span>
              </label>
              <label>
                <PhoneInput
                  name="phone"
                  placeholder={formatMessage({ id: "contactPhone" })}
                  value={phoneValue}
                  onChange={(value) => setPhoneValue(value)}
                />
                <span>
                  <FormattedMessage id="contactPhone" />
                </span>
              </label>
              <label>
                <textarea name="message" placeholder="Message" required />
                <span>Message *</span>
              </label>
              <label>
                <input type="checkbox" name="consent" required />
                <span>
                  <FormattedMessage id="contactConsent" />
                </span>
              </label>
              <label>
                <input type="checkbox" name="_optin" />
                <span dangerouslySetInnerHTML={{ __html: newsletter }} />
              </label>
              <hr />
              <button type="submit" className="link">
                <FormattedMessage id="contactSend" />
              </button>
              <ReCAPTCHA
                ref={recaptchaEl}
                size="invisible"
                sitekey="6LcCWWgcAAAAAMX0Bfo5GDtTRuIyaLjDXcVSaBvX"
                onChange={handleChange}
              />
            </form>
          </>
        )}
        {success === false && (
          <p>
            <FormattedMessage id="error" />
          </p>
        )}
        {success && <div dangerouslySetInnerHTML={{ __html: confirmation }} />}
      </RichText>
      {success && (
        <StaticImage src="../assets/mountains-2.jpg" quality={80} alt="" />
      )}
    </>
  );
};

export default ContactForm;
